<template>
  <div>
    <headerImg :src="img" height="640" />
    <div class="content">
      <div class="content-title">常见问题</div>
      <hr />
      <div class="faq-list" v-if="selectIndex === null">
        <div v-for="(item, index) in question" :key="index">
          <a @click="touchItem(index)"><i class="iconfont duozhi-icon-jia"></i>{{item}}</a>
        </div>
      </div>
      <div class="faq-content" v-else>
        <div class="title">{{content.q}}</div>
        <template v-for="(item, index) in content.r">
          <div class="content-text" v-if="item.type == 1" :key="index">{{item.content}}</div>
          <img :title="content.q" v-if="item.type == 2" :key="index" :src="item.content" alt="">
        </template>
      </div>
      <div v-if="selectIndex != null" style="height: 50px;font-size: 14px;">
        <div @click="touchItem(selectIndex - 1)" :title="question[selectIndex - 1]" style="color: #b6b9ba; float: left;cursor: pointer;" v-if="selectIndex != 0">上一篇：<span style="color: #535353">{{question[selectIndex - 1]}}</span></div>
        <div @click="touchItem(selectIndex + 1)" :title="question[selectIndex + 1]" style="color: #b6b9ba; float: right;cursor: pointer;" v-if="question[selectIndex + 1]">下一篇：<span style="color: #535353">{{question[selectIndex + 1]}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/images/customerService/haederimg.png"
import headerImg from "@/components/headerImg.vue"
import faq from "@/assets/json/faq_c.json"
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      img,
      question: faq.map(it => it.q),
      selectIndex: null,
      content: {}
    }
  },
  methods: {
    touchItem(index){
      this.selectIndex = index
      this.content = faq[index]

    }
  }

}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  .content-title{
    line-height: 31px;
    font-size: 21px;
    color: #333333;
    margin: 40px 0 10px 0;
  }
  .faq-list{
    margin-bottom: 100px;
    & > div{
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      background: #eeeeee;
      margin: 5px 0;
      padding: 0 15px;
      .iconfont{
        font-size: 20px;
        color: #f7af41;
      }
      a{
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .faq-content{
    margin-bottom: 50px;
    background: #ecf1f3;
    padding: 0 100px;
    padding-bottom: 100px;
    border-radius: 10px;
    .title{
      text-align: center;
      line-height: 150px;
      font-size: 30px;
    }
    .content-text{
      color: #424242;
      font-size: 16px;
      line-height: 30px;
    }
    img{
      width: 100%;
      margin: 10px 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .content{
    max-width: 98%!important;
    .content-subTitle{
      font-size: 14px;
      line-height: 24px;
    }
    .faq-list{
      & > div{
        line-height: 30px;
      }
    }
    .faq-content{
      padding: 20px 10px;
      .title{
        text-align: left;
        font-size: 16px;
        line-height: 30px;
        font-weight: 600;
      }
      .content-text{
        font-size: 12px;
      }
    }
  }
}

</style>